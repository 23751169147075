import { render, staticRenderFns } from "./DangerOccurVideo.vue?vue&type=template&id=13bd2c28&"
import script from "./DangerOccurVideo.vue?vue&type=script&lang=js&"
export * from "./DangerOccurVideo.vue?vue&type=script&lang=js&"
import style0 from "./DangerOccurVideo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\001.src\\003.aikl_wrokspace\\aikl_v2_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13bd2c28')) {
      api.createRecord('13bd2c28', component.options)
    } else {
      api.reload('13bd2c28', component.options)
    }
    module.hot.accept("./DangerOccurVideo.vue?vue&type=template&id=13bd2c28&", function () {
      api.rerender('13bd2c28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/biz/DangerOccurVideo.vue"
export default component.exports